import { userAxios } from '@/services'

const consultPriceList = async (perPage) => {
    try {
        return await userAxios.get(`consult_price_default/index/${perPage}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const consultPriceCreate = async (data) => {
    try {
        return await userAxios.post('consult_price/default', data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const consultPriceUpdate = async (id, data) => {
    try {
        return await userAxios.put(`consult_price/${id}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const consultPriceDelete = async (id) => {
    try {
        return await userAxios.delete(`consult_price/${id}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const consultPricePagination = async (perPage, page) => {
    try {
        return await userAxios.get(`consult_price_default/index/${perPage}?page=${page}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const consultPriceFilter = async (data) => {
    try {
        return await userAxios.post(`consult_price/filter`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const consultPromoList = async (perPage) => {
    try {
        return await userAxios.get(`consult_price_promotion/index/${perPage}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const consultPromoView = async (id) => {
    try {
        return await userAxios.get(`consult_price/campaigns/${id}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const consultPromotionCreate = async (data) => {
    try {
        return await userAxios.post('consult_price/campaigns', data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const consultPromoPagination = async (perPage, page) => {
    try {
        return await userAxios.get(`consult_price_promotion/index/${perPage}?page=${page}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const rateTypesList = async (perPage, page) => {
    try {
        return await userAxios.get(`rate_types/index/${perPage}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

export default {
    consultPriceList,
    consultPriceCreate,
    consultPriceUpdate,
    consultPriceDelete,
    consultPricePagination,
    consultPriceFilter,
    consultPromoList,
    consultPromoView,
    consultPromotionCreate,
    consultPromoPagination,
		rateTypesList
}