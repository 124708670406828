<template>
  <section>
    <MembershipFilter
      v-if="
        $can('filter', 'module_modules.type_of_modules.memberships.membership')
      "
      :loading="loading"
      @searchFilter="searchFilter"
      @filterPage="filterPage"
    />
    <b-card>
      <TitleTable titleTable="Memberships" iconTable="GitBranchIcon" />
      <div class="m-1">
        <MembershipEdit
          v-if="isEditMembershipSidebarActive"
          :is-edit-membership-sidebar-active.sync="
            isEditMembershipSidebarActive
          "
          :itemEdit="itemEdit"
          @editMembershipSuccess="editMembershipSuccess"
        />
        <MembershipDetails
          :is-add-new-membership-sidebar-active.sync="
            isAddNewMembershipSidebarActive
          "
          :membershipId="idMembership"
          @createMembership="createMembership"
        />
        <MembershipSetting
          v-if="isSettingMembershipSidebarActive"
          :editMembership="itemEdit"
          @closeModalMembership="closeModalMembership"
          @createMembership="createMembership"
        />
        <!-- Table Top -->
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t("Show") }}</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t("Entries") }}</label>
          </b-col>
          <!-- {{ $t("Search") }} -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <GoBack class="mr-2" />
              <b-button
                v-if="
                  $can(
                    'store',
                    'module_modules.type_of_modules.memberships.membership'
                  )
                "
                variant="primary"
                @click="isSettingMembershipSidebarActive = true;"
                :disabled="loading"
              >
                <span v-if="!loading" class="text-nowrap"
                  >{{ $t("Add") }} Membership</span
                >
                <span v-else>
                  <SpinnerLoading />
                </span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <LoadingTable v-if="items === null" :columns="fields.length" />

      <b-table
        v-else
        :items="items"
        :fields="fields"
        hover
        bordered
        responsive
        primary-key="id"
        class="position-relative"
        show-empty
        empty-text="No matching records found"
      >
        <template #cell(status)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{
              data.item.status ? $t("Active") : $t("Inactive")
            }}</span>
          </div>
        </template>
        <template #cell(actions)="data">
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-primary"
            class="btn-icon"
            v-b-tooltip.hover.top="'Edit'"
            v-if="
              $can(
                'update',
                'module_modules.type_of_modules.memberships.membership'
              )
            "
            @click="settingMembership(data.item)"
          >
            <feather-icon icon="EditIcon" />
          </b-button>

          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-primary"
            class="btn-icon"
            v-b-tooltip.hover.top="'See details'"
            v-if="
              $can(
                'update',
                'module_modules.type_of_modules.memberships.membership'
              )
            "
            @click="isAddNewMembershipSidebarActive = true; idMembership = data.item.id"
          >
            <feather-icon icon="EyeIcon" />
          </b-button>

          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-danger"
            class="btn-icon"
            v-b-tooltip.hover.top="'Delete'"
            v-if="
              $can(
                'destroy',
                'module_modules.type_of_modules.memberships.membership'
              )
            "
            @click="deleteMembership(data.item)"
          >
            <feather-icon icon="TrashIcon" />
          </b-button>
        </template>
      </b-table>
      <PaginationTable
        :dataMeta="dataMeta"
        :totalUsers="totalUsers"
        :perPage="perPage"
        :currentPage="currentPage"
        @page-changed="pageChanged"
      />
    </b-card>
    <ToastNotification ref="toast" />
  </section>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BBadge,
  BAvatar,
  BButton,
  VBTooltip,
} from "bootstrap-vue";
import { ref } from "@vue/composition-api";

import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";

import { notificationCheck } from "@/mixins/NotificationSetup";

import ToastNotification from "@/components/ToastNotification";
import SpinnerLoading from "@/components/SpinnerLoading";
import PaginationTable from "@/components/PaginationTable";
import TitleTable from "@/components/TitleTable.vue";
import GoBack from "@/components/ButtonBack.vue";
import LoadingTable from "@/components/LoadingTable.vue";

import MembershipEdit from "./MembershipEdit.vue";
import MembershipDetails from "./MembershipDetails.vue";
import MembershipFilter from "./MembershipFilter.vue";
import MembershipSetting from "./MembershipSetting.vue";
import axiosCV from "@/services/admin/membership";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BBadge,
    BAvatar,
    BButton,

    MembershipEdit,
    MembershipDetails,
    MembershipFilter,
    MembershipSetting,

    ToastNotification,
    SpinnerLoading,
    PaginationTable,
    TitleTable,
    GoBack,
    LoadingTable,

    vSelect,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      fields: [
        // A virtual column that doesn't exist in items
        "id",
        // A column that needs custom formatting
        { key: "name", label: "Name", sortable: true },
        { key: "price", label: "Price" },
        // { key: "quantity_beneficiaries", label: "Beneficiaries" },
        { key: "description", label: "description" },
        { key: "price", label: "Price" },
        { key: "status", label: "Status" },
        // A virtual column made up from two fields
        { key: "actions", label: "Actions" },
      ],
      idMembership: 0,
      items: null,
      loading: false,
      isEditMembershipSidebarActive: false,
      isAddNewMembershipSidebarActive: false,
      itemEdit: null,
      dataMeta: { from: 1, to: 1, of: 1 },
      totalUsers: 0,
      perPage: 10,
      currentPage: {
        page: 1,
      },
      perPageOptions: [10, 25, 50, 100],
      filtro: false,
      membershipFilter: null,
      isSettingMembershipSidebarActive: false,
    };
  },
  mounted() {
    this.getMembership();
  },
  watch: {
    perPage(newVal) {
      if (!this.filtro) {
        this.getMembership();
      } else {
        this.searchFilter(this.membershipFilter);
      }
    },
  },
  methods: {
    getMembership() {
      axiosCV
        .membershipList(this.perPage)
        .then(({ data, current_page, total, per_page, ...res }) => {
          this.items = data;
          this.totalUsers = total;
          this.dataMeta.of = total;
          this.dataMeta.from = res.from;
          this.dataMeta.to = res.to;
          this.currentPage.page = current_page;
        });
    },
    editMembership(item) {
      this.isEditMembershipSidebarActive = true;
      this.itemEdit = ref(JSON.parse(JSON.stringify(item)));
    },
    editMembershipSuccess(value) {
      if (value) {
        this.$refs.toast.success("Type Membership updated successfully");
      } else {
        this.$refs.toast.error("Error updating type membership");
      }
      this.isEditMembershipSidebarActive = false;
      this.pageChanged(this.currentPage.page);
    },
    createMembership({ registro }) {
      const { type, message } = registro;
      this.$refs.toast[type](message);
      this.isSettingMembershipSidebarActive = false;
      this.isAddNewMembershipSidebarActive = false;
      this.pageChanged(this.currentPage.page);
    },
    deleteMembership(item) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          axiosCV.membershipDelete(item.id).then((res) => {
            const { type, message } = notificationCheck(res);
            this.$refs.toast[type](message);
            this.pageChanged(this.currentPage.page);
          });
        }
      });
    },
    pageChanged(page) {
      if (!this.filtro) {
        axiosCV
          .membershipPagination(this.perPage, page)
          .then(({ data, total, current_page, ...res }) => {
            this.items = data;
            this.totalUsers = total;
            this.dataMeta.of = total;
            this.dataMeta.from = res.from;
            this.dataMeta.to = res.to;
            this.currentPage.page = current_page;
          });
      } else {
        axiosCV
          .membershipFilterPagination(this.perPage, page, this.membershipFilter)
          .then(({ data, total, current_page, ...res }) => {
            this.items = data;
            this.totalUsers = total;
            this.dataMeta.of = total;
            this.dataMeta.from = res.from;
            this.dataMeta.to = res.to;
            this.currentPage.page = current_page;
          });
      }
    },
    searchFilter(value) {
      if (this.filtro) {
        this.loading = true;
        this.membershipFilter = value;
        axiosCV
          .membershipFilter(this.perPage, value)
          .then(({ data, total, current_page, ...res }) => {
            this.loading = false;
            this.items = data;
            this.totalUsers = total;
            this.dataMeta.of = total;
            this.dataMeta.from = res.from;
            this.dataMeta.to = res.to;
            this.currentPage.page = current_page;
          })
          .catch(() => {
            this.loading = false;
          });
      } else {
        this.getMembership();
      }
    },
    filterPage(value) {
      this.filtro = value;
    },
    settingMembership(item) {
      this.itemEdit = ref(JSON.parse(JSON.stringify(item)));
      this.isSettingMembershipSidebarActive = true;
    },
    closeModalMembership() {
      this.isSettingMembershipSidebarActive = false;
      this.itemEdit = null;
    },
  },
};
</script>

<style></style>
