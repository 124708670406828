<template>
  <b-modal id="add-new-membership-sidebar" :visible="isAddNewMembershipSidebarActive" hide-footer bg-variant="white"
    size="lg" shadow backdrop no-header right
    @change="(val) => $emit('update:is-add-new-membership-sidebar-active', val)" @shown="onShown">
    <template #default="{ hide }">
      <div class="membership-details">
        <b-card>
          <h1>Name: {{ membershipData.name }}</h1>
          <h3>Created By: {{ membershipData.user_name }}</h3>
          <h5>Price: {{ membershipData.price }}</h5>
          <h5>Beneficiaries: {{ membershipData.quantity_beneficiaries }}</h5>
          <h5>Description: {{ membershipData.description }}</h5>
        </b-card>
        <div v-if="membershipData.consult_prices && membershipData.consult_prices.length">
          <h3 class="pt-3">Consult prices</h3>
          <b-table :fields="fields" :items="membershipData.consult_prices">
            <template #cell(benefit)="data">{{ data.item.name }}</template>
            <template #cell(renewal_in_months)="data">{{ data.item.renewal }}</template>
          </b-table>
        </div>
        <div v-if="membershipData.diagnostic_imagings && membershipData.diagnostic_imagings.length">
          <h3 class="pt-3">Diagnostic imagings</h3>
          <b-table :fields="fields" :items="membershipData.diagnostic_imagings">
            <template #cell(benefit)="data">{{ data.item.name }}</template>
            <template #cell(renewal_in_months)="data">{{ data.item.renewal }}</template>
          </b-table>
        </div>
        <div v-if="membershipData.laboratories && membershipData.laboratories.length">
          <h3 class="pt-3">Laboratories</h3>
          <b-table :fields="fields" :items="membershipData.laboratories">
            <template #cell(benefit)="data">{{ data.item.name }}</template>
            <template #cell(renewal_in_months)="data">{{ data.item.renewal }}</template>
          </b-table>
        </div>
        <div v-if="membershipData.laboratories_pack && membershipData.laboratories_pack.length">
          <h3 class="pt-3">Laboratories panel</h3>
          <b-table :fields="fields" :items="membershipData.laboratories_pack">
            <template #cell(benefit)="data">{{ data.item.name }}</template>
            <template #cell(renewal_in_months)="data">{{ data.item.renewal }}</template>
          </b-table>
        </div>
        <div v-if="membershipData.procedures && membershipData.procedures.length">
          <h3 class="pt-3">Procedures</h3>
          <b-table :fields="fields" :items="membershipData.procedures">
          </b-table>
        </div>
        <div v-if="membershipData.tests && membershipData.tests.length">
          <h3 class="pt-3">Tests</h3>
          <b-table :fields="fields" :items="membershipData.tests">
            <template #cell(benefit)="data">{{ data.item.name }}</template>
            <template #cell(renewal_in_months)="data">{{ data.item.renewal }}</template>
          </b-table>
        </div>
        <div v-if="membershipData.treatments && membershipData.treatments.length">
          <h3 class="pt-3">Treatments</h3>
          <b-table :fields="fields" :items="membershipData.treatments">
            <template #cell(benefit)="data">{{ data.item.name }}</template>
            <template #cell(renewal_in_months)="data">{{ data.item.renewal }}</template>
          </b-table>
        </div>
      </div>
      <hr />
      <b-button variant="primary" class="float-right" @click="hide">
        Close
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BTable,
  BCard
} from "bootstrap-vue";
import formValidation from "@core/comp-functions/forms/form-validation";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { notificationCheck } from "@/mixins/NotificationSetup";
import SpinnerLoading from "@/components/SpinnerLoading";

import vSelect from "vue-select";
import { required } from "@validations";
import Ripple from "vue-ripple-directive";
import { onMounted, ref } from "@vue/composition-api";

import axiosCT from "@/services/admin/membership";
import axiosCP from "@/services/admin/consults/consultPrices";

export default {
  components: {
    BTable,
    BCard,
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    SpinnerLoading,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewMembershipSidebarActive",
    event: "update:is-add-new-membership-sidebar-active",
  },
  props: {
    isAddNewMembershipSidebarActive: {
      type: Boolean,
      required: true,
    },
    membershipId: {
      default: 0,
      required: true,
      type: Number
    }
  },
  data() {
    return {
      required,
      fields: ["Benefit", "cant", "discount_rate", "renewal_in_months", "special_price"]
    };
  },
  setup(props, { emit }) {
    const optionsStatus = [
      { text: "Active", value: true },
      { text: "Inactive", value: false },
    ];
    const stateDataTem = {
      name: "",
      consult_price_id: null,
      price: 0,
      beneficiaries: 0,
      status: true,
    };
    const stateData = ref(JSON.parse(JSON.stringify(stateDataTem)));

    // const resetuserData = () => {
    //   stateData.value = JSON.parse(JSON.stringify(stateDataTem));
    // };
    // const { refFormObserver, getValidationState, resetForm } =
    //   formValidation(resetuserData);

    onMounted(() => {
      getConsultPrice();
    });

    const optionsConsultPrice = ref([]);
    const membershipData = ref({})
    const onShown = () => {
      axiosCT.membershipShow(props.membershipId).then(({ registro }) => {
        membershipData.value = registro
      })
    }
    const getConsultPrice = () => {
      axiosCP
        .consultPriceList(10)
        .then(({ data }) => {
          optionsConsultPrice.value = data;
        })
        .catch(() => {
          optionsConsultPrice.value = [];
        });
    };

    const loading = ref(false);
    const onSubmit = () => {
      loading.value = true;
      const datos = {
        ...stateData.value,
      };
      axiosCT
        .membershipCreate(datos)
        .then((res) => {
          loading.value = false;
          resetuserData();
          const { type, message } = notificationCheck(res);
          emit("createMembership", { registro: { type, message } });
        })
        .catch(() => {
          loading.value = false;
          emit("createMembership", {
            registro: { type: "danger", message: "Membership type not added" },
          });
        });
    };
    return {
      loading,
      stateData,
      optionsStatus,
      optionsConsultPrice,
      onSubmit,
      // refFormObserver,
      // getValidationState,
      // resetForm,
      onShown,
      membershipData
    };
  },
};
</script>

<style>
.membership-details {
  text-align: center;
  justify-content: center;
  align-content: center;
}
</style>
