<template>
  <div class="d-flex" v-b-tooltip.hover.top="procedure.name">
    <b-form-group label="Benefit" label-for="name" class="mr-1" style="width: 30%">
      <b-form-input id="name" disabled :value="procedure.name" />
    </b-form-group>

    <b-form-group label="Cant" label-for="cant" class="mr-1">
      <b-form-input type="number" id="cant" number v-model="procedure.cant" :state="isRenewalValid" />
    </b-form-group>

    <b-form-group label="Renewal in months" label-for="renewal" class="mr-1 w-50">
      <v-select id="renewal" placeholder="Unlimited" v-model="procedure.renewal" :options="monthsOptions"
        :reduce="(value) => value.value" label="label" :state="true" />
    </b-form-group>

    <b-form-group label="Special Price" label-for="special_price" class="mr-1">
      <b-form-input type="number" id="special_price" v-model="procedure.special_price" :state="isValidPrice" />
    </b-form-group>

    <b-form-group label="Discount Rate" label-for="discount_rate">
      <b-form-input type="number" id="discount_rate" placeholder="%" v-model="procedure.discount_rate"
        :state="isValidPrice" />
    </b-form-group>
  </div>
</template>

<script>
import { BFormGroup, BFormInput, VBTooltip } from "bootstrap-vue";
import vSelect from "vue-select";

export default {
  components: {
    BFormGroup,
    BFormInput,

    vSelect,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    procedure: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isRenewalValid() {
      return this.procedure.cant > 0 || !this.procedure.renewal;
    },
    isValidPrice() {
      let isValid = false
      if (this.procedure.consult_price) return true
      if (!this.procedure.special_price && !this.procedure.discount_rate) return isValid
      if (this.procedure.special_price != '' && this.procedure.special_price >= 0) {
        isValid = this.procedure.discount_rate == '' || this.procedure.discount_rate == null
      }
      if (this.procedure.discount_rate != "" && this.procedure.discount_rate > 0) {
        isValid = this.procedure.special_price == "" || this.procedure.special_price == null
      }
      return isValid
    },
  },
  watch: {
    procedure: {
      handler(newVal, oldVal) {
        this.$store.state.isValidMemberrshipSettings = this.isRenewalValid && this.isValidPrice
      }, deep: true
    }
  },
  setup(props) {
    const monthsOptions = Array.from({ length: 12 }, (_, index) => ({
      label: `${index + 1}`,
      value: index + 1,
    }));
    return {
      monthsOptions,
    };
  },
};
</script>

<style></style>
