<template>
  <b-modal id="modal-add-new-patient" no-header hide-header hide-footer size="lg" centered
    v-model="conditionAddNewPatient" @hide="onHide" @shown="onShown">
    <validation-observer ref="refFormObserver">
      <b-form @submit.prevent="onSubmit">
        <form-wizard ref="wizardRef" :title="null" :subtitle="null" :color="color" class="steps-transparent mb-3">
          <tab-content title="Membership Details" icon="feather icon-file-text">
            <div v-if="!loadingForm">
              <!-- Full Name -->
              <validation-provider #default="validationContext" name="name" rules="required">
                <b-form-group :label="$t('Form.Name')" label-for="name">
                  <b-form-input id="name" v-model="stateData.name" autofocus
                    :state="getValidationState(validationContext)" trim placeholder="Membership name" />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <!-- Price -->
              <validation-provider #default="validationContext" name="Price" rules="required|min_value:0|decimal:2">
                <b-form-group label="Price" label-for="price">
                  <b-form-input id="price" v-model="stateData.price" :state="getValidationState(validationContext)" trim
                    placeholder="Price" />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <!-- Beneficiaries -->
              <validation-provider #default="validationContext" name="Beneficiaries" rules="required">
                <b-form-group label="Beneficiaries" label-for="beneficiaries">
                  <b-form-input id="beneficiaries" v-model="stateData.quantity_beneficiaries"
                    :state="getValidationState(validationContext)" trim placeholder="0" />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <validation-provider #default="{ errors }" :name="$t('Facilities')" rules="required" class="w-100">
                <FieldForm fieldType="select" v-model="stateData.facilities" isMultiple :options="facilityOptions"
                  label="facilities" labelSelect="name" placeholder="Facilities..."
                  :reduceFunction="option => option.id" inputId="facilities" :errors="errors[0]" />
              </validation-provider>

              <!-- status -->
              <validation-provider #default="validationContext" name="Status" rules="required">
                <b-form-group :label="$t('Form.Status')" label-for="status">
                  <v-select id="status" v-model="stateData.status" :state="getValidationState(validationContext)"
                    :options="optionsStatus" :reduce="(value) => value.value" label="text" />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <!-- Form Actions -->
              <!-- <div class="d-flex mt-2">
  <b-button
    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
    variant="primary"
    class="mr-2"
    type="submit"
    :disabled="loading"
  >
    <span v-if="!loading">{{ $t("Add") }}</span>
    <span v-else>
      <SpinnerLoading />
    </span>
  </b-button>
  <b-button
    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
    type="button"
    variant="outline-danger"
    @click="hide"
  >
    {{ $t("Cancel") }}
  </b-button>
</div> -->
              <validation-provider #default="{ errors }" :name="$t('Form.Description')" rules="required" class="w-100">
                <FieldForm fieldType="textarea" v-model="stateData.description" placeholder="Description..."
                  inputId="description" :label="$t('Form.Description')" :errors="errors[0]" />
              </validation-provider>
            </div>
            <SpinnerLoading v-else />
          </tab-content>
          <tab-content title="Settings membership" icon="feather icon-file-text">
            <div class="mb-13 text-center">
              <!-- <h2 class="mb-1">Settings Membership</h2> -->
              <div class="text-muted">
                If you need more info, please check see the
                <router-link to="/help-center/tutorials" class="fw-bold link-primary">
                  {{ $t("Tutorials") }}</router-link>.
              </div>
            </div>
            <div v-if="!loadingForm">
              <!-- consult price -->
              <fieldset>
                <validation-provider #default="{ errors }" name="consult price" rules="required">
                  <FieldForm fieldType="select" v-model="consultPricesRef" :options="optionsConsultPrice"
                    placeholder="Seleccione..." inputId="consult_prices" label="consult prices" labelSelect="name"
                    :errors="errors[0]" @selected="addItem('consult_prices', [$event])"
                    @deselected="deleteItem('consult_prices', [$event])" />
                </validation-provider>

                <div v-for="procedure in stateData.consult_prices" :key="'cp_' + procedure ? procedure.id : 999"
                  class="mb-1">
                  <OptionsMembershipSettings :procedure="procedure" />
                </div>
              </fieldset>
              <!-- Laboratory -->
              <fieldset>
                <validation-provider #default="{ errors }" name="Laboratory" rules="">
                  <FieldForm fieldType="select" v-model="laboratoryRef" :options="laboratoryOptions"
                    placeholder="Seleccione..." inputId="laboratory" label="Laboratory" labelSelect="name"
                    :isMultiple="true" :errors="errors[0]" @selected="addItem('laboratories', $event)"
                    @deselected="deleteItem('laboratories', $event)" />
                </validation-provider>

                <div v-for="procedure in stateData.laboratories" :key="'laboratory_' + procedure.id" class="mb-1">
                  <OptionsMembershipSettings :procedure="procedure" />
                </div>
              </fieldset>

              <!-- lab packages -->
              <fieldset>
                <validation-provider #default="{ errors }" name="Laboratory Pack" rules="">
                  <FieldForm fieldType="select" v-model="laboratoryPackRef" :options="laboratoryPackOptions"
                    placeholder="Seleccione..." inputId="laboratory_pack" label="Laboratory Panel" labelSelect="name"
                    :isMultiple="true" :errors="errors[0]" @selected="addItem('laboratories_pack', $event)"
                    @deselected="deleteItem('laboratories_pack', $event)" />
                </validation-provider>

                <div v-for="procedure in stateData.laboratories_pack" :key="'lab_packages_' + procedure.id"
                  class="mb-1">
                  <OptionsMembershipSettings :procedure="procedure" />
                </div>
              </fieldset>

              <!-- diagnostic imagings -->
              <fieldset>
                <validation-provider #default="{ errors }" name="Diagnostic Imagings" rules="">
                  <FieldForm fieldType="select" v-model="diagnosticRef" :options="diagnosticImagingOptions"
                    placeholder="Seleccione..." inputId="diagnostic" label="Diagnostic Imagings" labelSelect="name"
                    :isMultiple="true" :errors="errors[0]" @selected="addItem('diagnostic_imagings', $event)"
                    @deselected="deleteItem('diagnostic_imagings', $event)" />
                </validation-provider>

                <div v-for="procedure in stateData.diagnostic_imagings" :key="'diagnostic_' + procedure.id"
                  class="mb-1">
                  <OptionsMembershipSettings :procedure="procedure" />
                </div>
              </fieldset>

              <!-- test -->
              <fieldset>
                <validation-provider #default="{ errors }" name="Test" rules="">
                  <FieldForm fieldType="select" v-model="testRef" :options="testOptions" placeholder="Seleccione..."
                    inputId="test" label="Test" labelSelect="name" :isMultiple="true" :errors="errors[0]"
                    @selected="addItem('tests', $event)" @deselected="deleteItem('tests', $event)" />
                </validation-provider>

                <div v-for="procedure in stateData.tests" :key="'test_' + procedure.id" class="mb-1">
                  <OptionsMembershipSettings :procedure="procedure" />
                </div>
              </fieldset>

              <!-- test -->
              <fieldset>
                <validation-provider #default="{ errors }" name="Procedure" rules="">
                  <FieldForm fieldType="select" v-model="procedureRef" :options="procedureOptions"
                    placeholder="Seleccione..." inputId="procedure" label="Procedure" labelSelect="name"
                    :isMultiple="true" :errors="errors[0]" @selected="addItem('procedures', $event)"
                    @deselected="deleteItem('procedures', $event)" />
                </validation-provider>

                <div v-for="procedure in stateData.procedures" :key="'procedure_' + procedure.id" class="mb-1">
                  <OptionsMembershipSettings :procedure="procedure" />
                </div>
              </fieldset>

              <!-- test -->
              <fieldset>
                <validation-provider #default="{ errors }" name="Treatment" rules="">
                  <FieldForm fieldType="select" v-model="treatmentRef" :options="treatmentOptions"
                    placeholder="Seleccione..." inputId="treatment" label="Treatment" labelSelect="name"
                    :isMultiple="true" :errors="errors[0]" @selected="addItem('treatments', $event)"
                    @deselected="deleteItem('treatments', $event)" />
                </validation-provider>

                <div v-for="procedure in stateData.treatments" :key="'treatment_' + procedure.id" class="mb-1">
                  <OptionsMembershipSettings :procedure="procedure" />
                </div>
              </fieldset>

              <!-- status -->
              <!-- <b-form-checkbox v-model="stateData.update_all_now_rules">
                By selecting this field you agree to create a new configuration for this membership, leave it blank if
                you only want to modify it
              </b-form-checkbox> -->
            </div>
            <SpinnerLoading v-else />
          </tab-content>
          <template slot="footer" slot-scope="props">
            <div class="wizard-footer-left ml-1">
              <wizard-button v-if="props.activeTabIndex > 0" @click.native="props.prevTab()"
                :style="props.fillButtonStyle">{{ $t("Form.Previous") }}</wizard-button>
            </div>
            <div class="wizard-footer-right">
              <wizard-button v-if="props.isLastStep" @click.native="onSubmit"
                class="wizard-footer-right finish-button d-flex" :disabled="loading" :style="props.fillButtonStyle">
                <span v-if="!loading">{{ $t("Form.Submit") }}</span>
                <span v-else>
                  <SpinnerLoading />
                </span>
              </wizard-button>
              <wizard-button v-else @click.native="props.nextTab()" class="wizard-footer-right"
                :style="props.fillButtonStyle">{{ $t("Form.Next") }}</wizard-button>
            </div>
          </template>
        </form-wizard>
      </b-form>
    </validation-observer>
    <template #modal-footer>
      <b-button variant="primary" @click="conditionAddNewPatient = false">
        Close
      </b-button>
    </template>
    <ToastNotification ref="toast" />
  </b-modal>
</template>

<script>
import {
  BForm,
  BButton,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormCheckbox
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { FormWizard, TabContent, WizardButton, } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import { onMounted, ref, watch } from "@vue/composition-api";

import SpinnerLoading from "@/components/SpinnerLoading";
import ToastNotification from "@/components/ToastNotification";
import store from '@/store'
import { notificationCheck } from "@/mixins/NotificationSetup";

import OptionsMembershipSettings from "./components/OptionsMembershipSettings.vue";

import axiosMembershipSetting from "@/services/admin/membership/setting";
import axiosCT from "@/services/admin/membership";
import FieldForm from "@/components/form/FieldForm.vue";
import axiosCP from "@/services/admin/consults/consultPrices";
import vSelect from "vue-select";
import axiosFacility from "@/services/admin/facilities";

import { $themeColors } from "@themeConfig";

export default {
  components: {
    BForm,
    vSelect,
    BButton,
    BFormGroup,
    BFormCheckbox,
    ToastNotification,
    BFormInput,
    BFormInvalidFeedback,

    SpinnerLoading,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    OptionsMembershipSettings,
    FieldForm,
    FormWizard,
    TabContent,
    WizardButton
  },
  props: {
    editMembership: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      required,
      color: $themeColors.primary
    };
  },
  setup(props, { emit }) {
    const wizardRef = ref(null)
    const optionsStatus = [
      { text: "Active", value: true },
      { text: "Inactive", value: false },
    ];
    const facilityOptions = ref([])
    const conditionAddNewPatient = true;

    const stateDataTem = {
      membership_id: props.editMembership ? props.editMembership.value.id : 0,
      description: null,
      edit_configuration: false,
      update_all_now_rules: false,
      facilities: [],
      status: true,
      laboratories: [],
      consult_prices: [],
      laboratories_pack: [],
      diagnostic_imagings: [],
      tests: [],
      procedures: [],
      treatments: [],
    };
    const stateData = ref(JSON.parse(JSON.stringify(stateDataTem)));

    const onHide = () => {
      emit("closeModalMembership", false);
    };

    const isEditMembershipSetting = ref(false);

    const laboratoryRef = ref([]);
    const consultPricesRef = ref([]);
    const laboratoryPackRef = ref([]);
    const diagnosticRef = ref([]);
    const testRef = ref([]);
    const procedureRef = ref([]);
    const treatmentRef = ref([]);
    const optionsConsultPrice = ref([]);
    const getConsultPrice = () => {
      axiosCP
        .consultPriceList(10)
        .then(({ data }) => {
          optionsConsultPrice.value = data;
        })
        .catch(() => {
          optionsConsultPrice.value = [];
        });
    };

    onMounted(() => {
      getData();
      getConsultPrice();
      const configMembership = props.editMembership ?
        props.editMembership.value.id : false;
      if (configMembership) {
        isEditMembershipSetting.value = configMembership;
        getDeatilMembership();
      }
    });

    function onShown() {
      if (wizardRef.value) wizardRef.value.activateAll();
    }

    const loadingForm = ref(false)
    let oldState = {}
    const getDeatilMembership = async () => {
      try {
        loadingForm.value = true;

        const { registro } = await axiosCT.membershipShow(stateData.value.membership_id)

        const {
          diagnostic_imagings,
          laboratories,
          laboratories_pack,
          consult_prices,
          tests,
          procedures,
          treatments,
        } = registro;

        consultPricesRef.value = consult_prices;
        laboratoryRef.value = laboratories;
        laboratoryPackRef.value = laboratories_pack;
        diagnosticRef.value = diagnostic_imagings;
        treatmentRef.value = treatments;
        testRef.value = tests;
        procedureRef.value = procedures;

        Object.assign(stateData.value, registro);
        oldState = JSON.parse(JSON.stringify(stateData.value))
        Object.freeze(oldState)
        loadingForm.value = false;
        wizardRef.value.activateAll();
      } catch (error) {
        console.error('Error fetching membership data:', error);
        loadingForm.value = false;
      }
    };


    const laboratoryOptions = ref([]);
    const laboratoryPackOptions = ref([]);
    const diagnosticImagingOptions = ref([]);
    const testOptions = ref([]);
    const procedureOptions = ref([]);
    const treatmentOptions = ref([]);

    const getData = async () => {
      try {
        const data = await axiosMembershipSetting.allClinicProcedures(100)

        const { categoryLaboratories, laboratory, diagnosticImagings, test, procedures, treatments } = data.registro

        laboratoryPackOptions.value = categoryLaboratories.data;
        laboratoryOptions.value = laboratory.data;
        diagnosticImagingOptions.value = diagnosticImagings.data;
        testOptions.value = test.data;
        procedureOptions.value = procedures.data;
        treatmentOptions.value = treatments.data;
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const getPrice = (price, percentage = false) => {
      if (percentage) return price == 0 ? null : price
      return price == 0 ? "0" : price
    }

    const addItem = (nameVariable, value) => {
      const valueProcedure = value[value.length - 1];

      if (nameVariable == "consult_prices") {
        stateData.value[nameVariable] = [{
          name: valueProcedure.name,
          id: valueProcedure.id,
          cant: null,
          renewal: null,
          consult_price: true,
          special_price: getPrice(valueProcedure.price),
          discount_rate: getPrice(valueProcedure.percentage_value, true),
        }]
        return
      }
      stateData.value[nameVariable].push({
        name: valueProcedure.name,
        id: valueProcedure.id,
        cant: null,
        renewal: null,
        special_price: getPrice(valueProcedure.price),
        discount_rate: getPrice(valueProcedure.percentage_value, true),
      });
    };

    const deleteItem = (nameVariable, value) => {
      const valueProcedure = value;
      const variableItem = stateData.value[nameVariable];

      const index = variableItem.findIndex(
        (item) => item.id === valueProcedure.id
      );
      if (index !== -1) {
        variableItem.splice(index, 1);
      }
    };

    const resetstateData = () => {
      stateData.value = JSON.parse(JSON.stringify(stateDataTem));
    };

    function getFacilities() {
      axiosFacility
        .facilityList(20)
        .then(({ data }) => {
          facilityOptions.value = data;
        })
        .catch((error) => {
          console.log(error);
        });
    }
    getFacilities()
    const propertiesToCompare = [
      "consult_prices",
      "diagnostic_imagings",
      "laboratories",
      "laboratories_pack",
      "procedures",
      "tests",
      "treatments"
    ];

    watch(stateData, () => {
      for (let property of propertiesToCompare) {
        const objeto1String = JSON.stringify(oldState[property]);
        const objeto2String = JSON.stringify(stateData.value[property]);

        if (objeto1String !== objeto2String) {
          stateData.value.edit_configuration = true;
        }
      }
    }, { deep: true });

    const loading = ref(false);

    const refFormObserver = ref(null);
    const toast = ref(null)
    const onSubmit = async () => {
      const check = await refFormObserver.value.validate();
      if (!store.state.isValidMemberrshipSettings) return toast.value.danger("Verify the fields in your form!");
      if (check) {
        const axiosUrl = isEditMembershipSetting.value
          ? "membershipUpdate"
          : "membershipCreate";
        axiosCT[axiosUrl](
          isEditMembershipSetting.value,
          stateData.value
        ).then((res) => {
          const { type, message } = notificationCheck(res);
          emit("createMembership", { registro: { type, message } });
          resetstateData();
        });
        loading.value = true;
      } else {
        toast.value.danger("Verify the fields in your form!");
      }
    };
    const resetuserData = () => {
      stateData.value = JSON.parse(JSON.stringify(stateDataTem));
    };
    const { getValidationState, resetForm } =
      formValidation(resetuserData);
    return {
      toast,
      getValidationState,
      resetForm,
      optionsStatus,
      conditionAddNewPatient,
      loading,
      stateData,
      onHide,
      onSubmit,
      refFormObserver,

      laboratoryOptions,
      laboratoryPackOptions,
      diagnosticImagingOptions,
      testOptions,
      procedureOptions,
      treatmentOptions,

      laboratoryRef,
      laboratoryPackRef,
      diagnosticRef,
      testRef,
      procedureRef,
      treatmentRef,

      addItem,
      deleteItem,
      optionsConsultPrice,
      isEditMembershipSetting,
      wizardRef,
      onShown,
      consultPricesRef,
      loadingForm,
      facilityOptions
    };
  },
};
</script>
<style></style>