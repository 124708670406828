import { userAxios } from '@/services'

const membershipSettingCreate = async (data) => {
    try {
        return await userAxios.post('membership/configuration', data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const membershipSettingUpdate = async (data, membershipConfigurationId) => {
    try {
        return await userAxios.put(`membership/configuration/${membershipConfigurationId}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const membershipSettingDetail = async (membershipId) => {
    try {
        return await userAxios.get(`membership/configuration/${membershipId}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const allClinicProcedures = async (membershipId) => {
    try {
        return await userAxios.get(`administration-resources/all-clinic-procedures/${membershipId}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

export default {
    membershipSettingCreate,
    membershipSettingUpdate,
    membershipSettingDetail,
    allClinicProcedures
}