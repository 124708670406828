<template>
  <b-form-group :label="$t(label)" :label-for="inputId">
    <template v-if="fieldType === 'text'">
      <b-form-input
        :id="inputId"
        v-model="model"
        trim
        :placeholder="placeholder"
        :disabled="isDisabled"
      >
      </b-form-input>
    </template>
    <template v-if="fieldType === 'textarea'">
      <b-form-textarea
        :id="inputId"
        v-model="model"
        trim
        no-resize
        :placeholder="placeholder"
        :disabled="isDisabled"
      >
      </b-form-textarea>
    </template>
    <template v-if="fieldType === 'select'">
      <v-select
        :id="inputId"
        v-model="model"
        :label="labelSelect"
        :options="options"
        :multiple="isMultiple"
        :placeholder="placeholder"
        :disabled="isDisabled"
        :reduce="reduceFunction"
        @option:selected="handleOptionSelected"
        @option:deselected="handleOptionDeselected"
      >
      </v-select>
    </template>
    <small class="text-danger">{{ errors }}</small>
  </b-form-group>
</template>

<script>
import { BFormGroup, BFormInput, BFormTextarea } from "bootstrap-vue";

import vSelect from "vue-select";
export default {
  components: {
    vSelect,
    BFormGroup,
    BFormInput,
    BFormTextarea,
  },
  props: {
    value: {
      required: false,
    },
    label: {
      type: String,
      required: true,
    },
    inputId: {
      type: String,
      required: true,
    },
    fieldType: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
    },
    options: {
      type: Array,
      required: false,
    },
    labelSelect: {
      type: String,
      required: false,
    },
    errors: {
      type: String,
      required: false,
    },
    isMultiple: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    onItemSelected: {
      type: Function,
      required: false,
    },
    onItemDeselected: {
      type: Function,
      required: false,
    },
    reduceFunction: {
      type: Function,
      required: false,
    },
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
  methods: {
    handleOptionSelected(option) {
      this.$emit("selected", option);
    },
    handleOptionDeselected(option) {
      this.$emit("deselected", option);
    },
  },
};
</script>

<style></style>
